import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Flex, Box } from 'reflexbox/styled-components'

import baseTheme from 'theme'
import media from 'media'

import Link from 'components/Link'
import ProductIcon from 'components/ProductIcon'
import IconDynamicArrow from '@anvilco-pkg/ui/components/Icon/IconDynamicArrow'

const Container = styled.div``

const ViewDocumentation = styled.span`
  display: block;
  font-weight: ${baseTheme.fontWeights.black};
  margin-top: auto;
  display: flex;
  align-items: center;

  > * {
    margin-right: 10px;
  }
  &:last-child {
    margin-right: 0;
  }

  svg {
    display: block;
  }
`

const APISectionContainer = styled(Link)`
  text-decoration: none !important;
  font-weight: unset !important;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  color: ${baseTheme.colors.textColor};

  img {
    transition: transform 0.3s ease;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &:focus,
  &:active {
    color: ${baseTheme.colors.textColor};
  }

  &:hover {
    img {
      transform: translateY(-5px);
    }

    ${ViewDocumentation} {
      color: ${({ theme }) =>
        theme?.textColors?.link || baseTheme.textColors.link};
      svg {
        path {
          fill: ${({ theme }) =>
            theme?.textColors?.link || baseTheme.textColors.link};
        }
      }
    }
  }
`

const APISectionTitle = styled.h2`
  font-size: 22px;
  color: ${baseTheme.colors.inputBorderColorActive};
  margin-top: 0;
`
const APISectionContent = styled.div`
  margin-bottom: 20px;
`

const APISection = ({ to, productType, title, children }) => (
  <APISectionContainer to={to}>
    <ProductIcon type={productType} />
    <APISectionTitle>{title}</APISectionTitle>
    <APISectionContent>
      <p>
        <span>{children}</span>
      </p>
    </APISectionContent>
    <ViewDocumentation>
      <span>View documentation</span>{' '}
      <IconDynamicArrow width={60} direction="right" />
    </ViewDocumentation>
  </APISectionContainer>
)

APISection.propTypes = {
  to: PropTypes.string,
  productType: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
}

const ItemsContainer = styled.div``

const Column = styled(Flex)`
  flex-wrap: wrap;
`

const LeftBox = styled(Box).attrs({
  width: [1, 1 / 2],
  pr: [0, 20],
  pb: [60, 80],
})`
  ${media.tablet`
    align-items: stretch;
  `}
`

const RightBox = styled(Box).attrs({
  width: [1, 1 / 2],
  pl: [0, 20],
  pb: [60, 80],
})`
  ${media.tablet`
    align-items: stretch;
  `}
`

const APIInfo = () => {
  return (
    <Container>
      <ItemsContainer>
        <Column>
          <LeftBox>
            <APISection
              productType="gettingStarted"
              title="Getting started"
              to="/docs/api/getting-started/"
            >
              Get set up to use Anvil's APIs.
            </APISection>
          </LeftBox>

          <RightBox>
            <APISection
              productType="etchESign"
              title="Etch e-sign"
              to="/docs/api/e-signatures/"
            >
              Collect e-signatures from within your app.
            </APISection>
          </RightBox>
        </Column>

        <Column>
          <LeftBox>
            <APISection
              productType="pdfFilling"
              title="PDF filling"
              to="/docs/api/fill-pdf/"
            >
              Fill templatized PDFs using JSON data.
            </APISection>
          </LeftBox>

          <RightBox>
            <APISection
              productType="pdfGeneration"
              title="PDF generation"
              to="/docs/api/generate-pdf/"
            >
              Dynamically generate PDFs using JSON data.
            </APISection>
          </RightBox>
        </Column>

        <Column>
          <LeftBox>
            <APISection
              productType="embeddedBuilders"
              title="Embedded builders"
              to="/docs/api/embedded-builders/"
            >
              Embed Anvil builder UIs into your app to allow your users to build
              PDF templates & more.
            </APISection>
          </LeftBox>

          <RightBox>
            <APISection
              productType="workflows"
              title="Workflows"
              to="/docs/api/workflows/"
            >
              Collect data with a Webform, automatically fill PDFs, and gather
              e-signatures.
            </APISection>
          </RightBox>
        </Column>

        <Column>
          <LeftBox>
            <APISection
              productType="whitelabeling"
              title="White labeling"
              to="/docs/api/white-labeling/"
            >
              Rapidly style Anvil Workflows and e-signature packets to match
              your brand.
            </APISection>
          </LeftBox>

          <RightBox>
            <APISection
              productType="webhooks"
              title="Webhooks"
              to="/docs/api/webhooks/"
            >
              Get notifications for completed Workflows, completed e-signatures,
              and more.
            </APISection>
          </RightBox>
        </Column>

        <Column>
          <LeftBox>
            <APISection
              productType="objectShapes"
              title="Object shapes"
              to="/docs/api/object-references/"
            >
              Learn about the shapes of Anvil's common objects.
            </APISection>
          </LeftBox>

          <RightBox>
            <APISection
              productType="graphql"
              title="GraphQL guide"
              to="/docs/api/graphql/"
            >
              Programmatically access your account, create and edit Workflows &
              e-sign packets.
            </APISection>
          </RightBox>
        </Column>
      </ItemsContainer>
    </Container>
  )
}

export default APIInfo
