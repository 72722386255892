export { rgba } from 'polished'

const paperMaxWidth = 1266

const black = '#1D1F21'
const white = '#FFF'
const offWhite = '#FBFCFC'

const blue = '#61C9D7'
const green = '#5CEBA0'
const red = '#FD7A7D'
const yellow = '#FCE969'

// the keys 'light' and 'dark' refer to the mode of the site
const linkColor = {
  // TODO: ask adelya about both these colors, how does hoverGreen fit in?
  light: '#13AA5C',
  dark: '#7AEFB2',
}
const hoverGreen = '#37DF87'

const colors = {
  blacks: {
    5: '#F4F5F6',
    10: '#E9EBEC', // subsection bg color
    20: '#D4D7D9',
    30: '#B3B9BD',
    40: '#939BA0',
    50: '#6F787E',
    60: '#565E62',
    70: '#393E42',
  },
  blues: {
    5: '#EFF9FB',
    10: '#DFF4F7',
    20: '#B0E4EB',
    30: blue,
    40: '#05AAC0',
    50: '#0185A2',
    60: '#016179',
  },
  greens: {
    5: '#EDFDF5',
    10: '#DAFBEB',
    20: '#ABF6CF',
    30: green,
    40: '#2ED27C',
    50: '#0FAE5C',
    60: '#0F8548',
  },
  reds: {
    5: '#FFEBEB',
    10: '#FED7D8',
    20: '#FFB8B9',
    30: red,
    40: '#EF5557',
    50: '#E13436',
    60: '#C30F11',
  },
  yellows: {
    5: '#FFFCEB',
    10: '#FFF9D6',
    20: '#FFF4AD',
    30: yellow,
    40: '#F0D738',
    50: '#E2C508',
    60: '#D3B200',
  },
  black,
  white,
  offWhite,
  blue,
  green,
  hoverGreen,
  red,
  yellow,
}

// TODO: consolidate textColors / colors.textColor

// NOTE: these are the base link styles from before the "temp redesign"
// aka, before adelya's reign as design queen. We should probably update
// the colors here, but components have been updated (and should continue
// to support) overriding these values via the theme prop (check
// src/themes/base.js for an example of this).
const textColors = {
  link: '#0298bf',
  linkHover: '#0182a2',
}

colors.linkColor = linkColor
colors.textColor = colors.black
colors.inputBorderColor = '#E2EEF0'
colors.inputBorderColorActive = black

const fontSizes = {
  30: 12,
  40: 14,
  50: 16,
  60: 18,
  // why this shift to 20 increments?
  80: 20,
  100: 22,
  120: 24,
  140: 26,
  160: 28,
  200: 32,
  240: 36,
  300: 42,
  320: 44,
  340: 46,
  360: 48,
  380: 50,
  400: 52,
  520: 64,
}

const fontWeights = {
  lightest: 100,
  light: 200,
  thinner: 300,
  thin: 400,
  normal: 500,
  bold: 600,
  black: 700,
}

const fontStack = 'barlow, -apple-system, BlinkMacSystemFont, sans-serif'
const fontFamilies = {
  body: fontStack,
  heading: fontStack,
  monospace: 'Consolas, Monaco, "Courier New", monospace',
}

const shadows = {
  30: '0 0 4px 0 rgba(0,0,0,0.04)',
  40: '0 2px 15px 0 rgba(0,0,0,0.06)',
  50: '0px -4px 15px rgba(0, 0, 0, 0.08)',
  60: '0 2px 15px 0 rgb(0 0 0 / .1)',
}

const componentShadows = {
  normal: '0 3px 15px 0 rgba(0,0,0,0.1)',
  small: '0 2px 4px 0 rgba(0,0,0,0.1)',
  large: '0 3px 30px 0 rgba(0,0,0,0.06)',
  dark: '0 3px 15px 0 rgba(0,0,0,0.2)',
  largeDark: '0 5px 30px 0 rgba(0,0,0,0.2)',
  navbar: '0px -4px 15px rgba(30, 30, 30, 0.04)',
}

const button = {
  height: 36,
  heightLarge: 42,
  heightSmall: 28,
  heightHuge: 64,
  padding: '0 15px',
  paddingLarge: '0 20px',
  paddingSmall: '0 10px',
  paddingHuge: '0 30px',
}

const component = {
  paddingSmallest: '5px',
}

export default {
  colors,
  textColors,
  fontSizes,
  fontWeights,
  fontFamilies,
  shadows,
  paperMaxWidth,
  button,
  component,
  componentShadows,
}
